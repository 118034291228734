import React from "react"

function AuthorBio({ authorData }) {

  return (
    <section className="bg-black-100 md:mx-auto md:max-w-screen-md md:mb-14">

      <div className="flex flex-col md:flex-row gap-5 md:gap-6 pt-8 pb-4 md:px-6 mx-auto max-w-xs md:max-w-screen-md">

        <div className="flex flex-row md:flex-shrink-0 items-center gap-5">
          <img className="flex md:flex-grow w-11 md:w-24 h-14 md:h-32 object-cover" src={authorData.avatar} alt={authorData.name} loading="lazy" />
          <span className="md:hidden font-bold text-2xl text-green-700 xl:text-2xl">{authorData.name}</span>
        </div>

        <div className="flex flex-col gap-6">
          <span className="hidden md:flex font-bold text-2xl text-green-700 xl:text-2xl">{authorData.name}</span>
          <p className="text-black-600 text-sm text-justify">{authorData.description}</p>
        </div>

      </div>

    </section>
  )
}

export default AuthorBio