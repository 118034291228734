import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import PostsLayout from "../components/postsLayout"
import Author from "../components/underHeader/authorBio"

function BlogUser({ data, pageContext: { nextPagePath, previousPagePath, userUri } }) {

  const posts = data.allWpPost.nodes

  return (
    <Layout>

      <Seo title={data.wpUser.name} />

      <Author authorData={data.wpUser} />

      <PostsLayout posts={posts} nextPagePath={nextPagePath} previousPagePath={previousPagePath} uri={userUri} />

    </Layout>
  )
}

export default BlogUser

export const pageQuery = graphql`
  query WordPressBlogUserArchive($offset: Int!, $postsPerPage: Int!, $user: String!) {
    allWpPost(
      filter: {author: {node: {id: {in: [$user]}}}}
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "DD/MM/YYYY")
        title
        author {
          node {
            name
            uri
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400,
                  placeholder: BLURRED,
                  formats: [WEBP],
                  quality: 100
                )
              }
            }
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    }

    wpUser(id: {in: [$user]}) {
      name
      description
      avatar {
        url
      }
    }

  }
`
